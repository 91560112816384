import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Menu, X, LayoutDashboard, Users, CreditCard, Wallet, History, LogOut, ArrowUpCircle, ArrowDownCircle, Percent, Bell, User, Gamepad, EggFriedIcon, PlusCircle, List, ChevronDown, ChevronRight, Link } from "lucide-react";
import logo from '../assets/logo.png';

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();

  const [gamesExpanded, setGamesExpanded] = useState(false);


  const toggleGamesExpanded = () => {
    setGamesExpanded(!gamesExpanded);
  };


  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    navigate("/login");
    toggleSidebar()
  };

  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        toggleSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleSidebar]);

  return (
    <>
      <button 
        className="fixed top-4 left-4 z-20 text-gray-700 lg:hidden"
        onClick={toggleSidebar}
      >
        {isOpen ? <X className="text-white" size={24} /> : <Menu className="text-white"  size={24} />}
      </button>

      <div ref={sidebarRef} className={`w-64 min-h-screen bg-[#2B3141] shadow-md px-1 fixed top-0 left-0 z-[999] transition-all duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0`}>
        <div className=" flex items-center">
          <img src={logo} alt="Logo" className="h-auto w-[120px] mr-2" />
          {/* <span className="text-xl font-semibold text-gray-800">Justadmin</span> */}
        </div>
        <nav className="text-sm">
  <div className="mb-4 mt-4">
    <NavLink
      to="/"
      className={({ isActive }) =>
        `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-gray-300 hover:text-[#646ECD] ${
          isActive ? "bg-blue-100 text-[#646ECD]" : "text-gray-700 hover:bg-gray-100"
        }`
      }
    >
      <LayoutDashboard size={18} className="mr-3 text-gray-500 group-hover:text-[#646ECD]" />
      Dashboard
    </NavLink>
    <NavLink
      to="/users"
      className={({ isActive }) =>
        `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-gray-300 hover:text-[#646ECD] ${
          isActive ? "bg-blue-100 text-[#646ECD]" : "text-gray-700 hover:bg-gray-100"
        }`
      }
    >
      <Users size={18} className="mr-3 text-gray-500 group-hover:text-[#646ECD]" />
      Users
    </NavLink>
    <NavLink
      to="/deposit"
      onClick={toggleSidebar}
      className={({ isActive }) =>
        `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-gray-300 hover:text-[#646ECD] ${
          isActive ? "bg-blue-100 text-[#646ECD]" : "text-gray-700 hover:bg-gray-100"
        }`
      }
    >
      <ArrowUpCircle size={18} className="mr-3 text-gray-500 group-hover:text-[#646ECD]" />
      Deposit
    </NavLink>
    <NavLink
      onClick={toggleSidebar}
      to="/withdraw"
      className={({ isActive }) =>
        `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-gray-300 hover:text-[#646ECD] ${
          isActive ? "bg-blue-100 text-[#646ECD]" : "text-gray-700 hover:bg-gray-100"
        }`
      }
    >
      <ArrowDownCircle size={18} className="mr-3 text-gray-500 group-hover:text-[#646ECD]" />
      Withdraw
    </NavLink>
    <div>
      <button
        onClick={toggleGamesExpanded}
        className={`group flex items-center justify-between w-full py-2 px-4 rounded-lg transition duration-200 text-gray-300 hover:text-[#646ECD] hover:bg-gray-100 ${
          gamesExpanded ? "bg-blue-100 text-[#646ECD]" : "text-gray-700 hover:bg-gray-100"
        }`}
      >
        <div className="flex items-center">
          <Gamepad size={18} className="mr-3 text-gray-500 group-hover:text-[#646ECD]" />
          Manage Games
        </div>
        {gamesExpanded ? <ChevronDown size={18} className="group-hover:text-[#646ECD]" /> : <ChevronRight size={18} className="group-hover:text-[#646ECD]" />}
      </button>
      <div className={`ml-6 gap-2 overflow-hidden transition-all duration-300 ease-in-out ${
        gamesExpanded ? "max-h-20" : "max-h-0"
      }`}>
        <NavLink
          onClick={toggleSidebar}
          to="/games"
          className={({ isActive }) =>
            `group flex items-center py-2 px-4 gap-1 rounded-lg transition duration-200 text-gray-300 hover:text-[#646ECD] ${
              isActive ? "bg-blue-100 text-[#646ECD]" : "text-gray-700 hover:bg-gray-100"
            }`
          }
        >
          <PlusCircle size={18} className="mr-3 text-gray-500 group-hover:text-[#646ECD]" />
          Games
        </NavLink>
        <NavLink
          onClick={toggleSidebar}
          to="win-settings"
          className={({ isActive }) =>
            `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-gray-300 hover:text-[#646ECD] ${
              isActive ? "bg-blue-100 text-[#646ECD]" : "text-gray-700 hover:bg-gray-100"
            }`
          }
        >
          <List size={18} className="mr-3 text-gray-500 group-hover:text-[#646ECD]" />
          Win Setting
        </NavLink>
      </div>
    </div>
    <NavLink
      onClick={toggleSidebar}
      to="/affiliate"
      className={({ isActive }) =>
        `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-gray-300 hover:text-[#646ECD] ${
          isActive ? "bg-blue-100 text-[#646ECD]" : "text-gray-700 hover:bg-gray-100"
        }`
      }
    >
      <Link size={18} className="mr-3 text-gray-500 group-hover:text-[#646ECD]" />
      Affiliate
    </NavLink>
    <NavLink
      onClick={toggleSidebar}
      to="/seo-manager"
      className={({ isActive }) =>
        `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-gray-300 hover:text-[#646ECD] ${
          isActive ? "bg-blue-100 text-[#646ECD]" : "text-gray-700 hover:bg-gray-100"
        }`
      }
    >
      <User size={18} className="mr-3 text-gray-500 group-hover:text-[#646ECD]" />
      SEO Manager
    </NavLink>
    <NavLink
      onClick={toggleSidebar}
      to="/notification-settings"
      className={({ isActive }) =>
        `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-gray-300 hover:text-[#646ECD] ${
          isActive ? "bg-blue-100 text-[#646ECD]" : "text-gray-700 hover:bg-gray-100"
        }`
      }
    >
      <Bell size={18} className="mr-3 text-gray-500 group-hover:text-[#646ECD]" />
      Notification Settings
    </NavLink>
    <NavLink
      to="/house"
      onClick={toggleSidebar}
      className={({ isActive }) =>
        `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-gray-300 hover:text-[#646ECD] ${
          isActive ? "bg-blue-100 text-[#646ECD]" : "text-gray-700 hover:bg-gray-100"
        }`
      }
    >
      <Percent size={18} className="mr-3 text-gray-500 group-hover:text-[#646ECD]" />
      House Edge
    </NavLink>
    <NavLink
      onClick={toggleSidebar}
      to="/wallet"
      className={({ isActive }) =>
        `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-gray-300 hover:text-[#646ECD] ${
          isActive ? "bg-blue-100 text-[#646ECD]" : "text-gray-700 hover:bg-gray-100"
        }`
      }
    >
      <Wallet size={18} className="mr-3 text-gray-500 group-hover:text-[#646ECD]" />
      Wallet
    </NavLink>
    <NavLink
      onClick={toggleSidebar}
      to="/history"
      className={({ isActive }) =>
        `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-gray-300 hover:text-[#646ECD] ${
          isActive ? "bg-blue-100 text-[#646ECD]" : "text-gray-700 hover:bg-gray-100"
        }`
      }
    >
      <History size={18} className="mr-3 text-gray-500 group-hover:text-[#646ECD]" />
      History
    </NavLink>
    {/* <button
      onClick={handleLogout}
      className="flex items-center py-2 px-4 rounded-lg transition duration-200 text-gray-700 hover:bg-gray-100 text-gray-300 hover:text-[#646ECD]"
    >
      <LogOut size={18} className="mr-3 text-gray-500 hover:text-[#646ECD]" />
      Logout
    </button> */}
  </div>
</nav>

        <button
          onClick={handleLogout}
          className="flex items-center w-full py-2 px-4  text-gray-300 hover:text-[#646ECD] hover:bg-gray-100 rounded-lg transition duration-200 text-left mt-auto absolute bottom-4"
        >
          <LogOut size={18} className="mr-3" />
          Log out
        </button>
      </div>
    </>
  );
};

export default Sidebar;